/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageConsignment.js
Author:    John D. Kohl
Version:   0.20
Description:
The Details page for the New Quote workflow.

History:
0.20  11-10-24 JRB   (ld0013078) Extracted ElectronicConsignment function to its own file and replaced the function with the new one from ElectronicConsignment.js
0.19  11-10-24 JRB   (ld0013078) Added separate Consignment and label buttons. Download now downloads label and consignment separately.
0.18  10-10-23 JRB   (ld0012275) Quote Convert no longer uses its own path. Uses standard context, only drop freight is now separate
0.17  23-05-23 JRB   (ld0012104) Moved clear errors to its own useEffect which now only runs when we update the conNumber. Not when sendLoading or any part of the store changes.
0.16  22-05-23 JDK   (ld0011932) Swapped manual and electronic connote options buttons.
0.15  19-05-23 JRB   (ld0012104) Clear errors on store change when Manual. Allow attempting to resend print requests IF we have changed the number (and hence don't have an error).
0.14  18-01-23 JDK   (ld0011885) Refactored 'context' objects into constants. This simplifies the switch statement, and also allows us to correctly route pickups converted from a quote that have been loaded from the existing pickups page.
0.13  08-12-22 DBL   (ld0011805) It was submitting the manual conNumber when clicking Electronic ConNote
0.12  17-11-22 DBL   (ld0011761) Now receives apiPickupSuccess
0.11  09-11-22 JDK   Added error handling for printing consignments, rolled back REST error handling (as it is a URI, no error to generate).
0.10  09-11-22 DBL   Added error handling for electronic consignents
0.09  01-11-22 JRB   (ld0011699) Changed the quote convert payload to have pickupOrConsignment parameter.
0.08  31-10-22 JRB   (ld0011710) Removed clear store code.
0.07  06-10-22 JRB   Disable the PDF buttons once we select them.
0.06  29-09-22 JDK   Use "checked" property of CardButtons to provide user feedback on when they've clicked a button.
0.05  20-09-22 JRB   Added setting of custom controls when we call handle submit to disable and change text.
0.04  19-09-22 JRB   Fixed context case for dropfreight, fixed instance of conNum to conNumber, fixed button enabled state which should've been enable instead of enable(d). Added check of manual entry of connumber to manual submit enable
0.03  23-08322 DBL   Change setModel to use authModal
0.02  19-07-22 DBL   changed connote to conType, and conNumber to connote
0.01	23-03-22 JDK   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom'
import CardForm, { CardButton } from '../Common/CardForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import { faClipboardListCheck, faDesktopArrowDown } from '@fortawesome/pro-solid-svg-icons';
import useRest from '../Session/useRest';
import useAuthenticate from '../Session/useAuthenticate';
import useNavigate from '../Navigation/useNavigate';
import { companyName } from '../Configuration/Config';
import { compactStore } from '../Common/utils';
import ElectronicConsignment from '../Common/ElectronicConsignment';

class init {
  constructor () { 
    this.conType = {value: '', error: null} // the type of consignment being generated
    this.conNumber = {value: '', error: null} // consignment number
    this.quoteNumber = {value: '', error: null} // used for drop freight
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  //console.log('Details Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;

  switch(action.name) {
    case 'conType':
      switch(newValue) {
        case 'manual':
          break;
        case 'electronic':
          return { [action.name]: { value: newValue, error: error } , conNumber: {value: '', error: null} }; // ld0011805 clear conNumber before submitting
        default:
          newValue = null;
      }
      break;

    case 'conNumber':
    case 'quoteNumber':
      break;

    case 'loadStore':
      if (Array.isArray(newValue)) newValue = newValue[9]; // Drop freight passes an Array
      const type = newValue.hasOwnProperty('conType') ? newValue : null; // if loading an exiting pickup, it wil not have it
      return {
        ...reducer(store, {name: 'conType', value: type}),
        ...reducer(store, {name: 'conNumber', value: newValue.conNumber ? newValue.conNumber : ''}),
        quoteNumber: {value: newValue.quoteNumber, error: null}
      };

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }
    
  return { [action.name]: { value: newValue, error: error } };
}


function PageConsignment({store, dispatcher, controls}) { // isPickup parameter deprecated
  const quoteNumber = (store.quoteNumber && store.quoteNumber.value) ? store.quoteNumber.value : null;
  const pickupNumber = (store.pickupNumber && store.pickupNumber.value) ? store.pickupNumber.value : null;
  const consignNumber = (store.conNumber && store.conNumber.value) ? store.conNumber.value : null;
  const location = useLocation(); // where are we coming from? important as the page has specific handling for different scenarios
  const qcContext = {
    mode: 'QC',
    payload: { ...compactStore(store), quoteNumber: quoteNumber, pickupOrConsignment: 1 },
    modal: {
      title: 'Quote Converted',
      description: [
        `Your pickup number is ${pickupNumber}`,
        <br />,
        `Your consignment number is ${consignNumber}`
      ],
    },
    url: 'quoteConvert',
    notice: 'Please ensure you provide a connote to the driver when freight is picked up.'
  }
  const dfContext = {
    mode: 'DF',
    payload: { ...compactStore(store), quoteNumber: quoteNumber, convertQuote: true },
    modal: {
      title: 'Completed',
      description: [
        `You can now drop your freight off at ${companyName}.`,
        <br />,
        `Please advise staff of your quote number: ${quoteNumber}`
      ]
    },
    url: 'dropfreight',
    notice: `When dropping off freight, please advise staff of your quote number: ${quoteNumber}`
  }
  const stdContext = {
    mode: 'PU',
    payload: { pickupNumber: pickupNumber, conNumber: consignNumber },
    modal: {
      title: 'Pickup Completed',
      description: 'Your pickup has been booked successfully.',
    },
    url: 'pickups/convert',
    notice: 'Please ensure you provide a connote to the driver when freight is picked up.'
  }

  const context = ((location) => {
    switch (location.pathname) {
      // case '/quote/convert': // we came from the quote workflow, and selected convert to pickup
      //   return qcContext;
      case '/quote/dropfreight': // we came from the quote workflow, and selected drop freight in
        return dfContext;
      default: // standard pickup behaviour
        // if (store.quoteNumber.value) return qcContext; // if we come here from existing pickups, we can't use the pathname to determine the correct context.
        // else return stdContext;
        return stdContext; // Always use standard context as it now should handle quote numbers.
    }
  })(location);

  // Hooks, custom or otherwise.
  const { authModal } = useAuthenticate()
  const { navState, changeState } = useNavigate()
  const navigate = useRouterNavigate();

  // REST definition
  const [send, sendData, sendError, sendLoading] = useRest('POST', context.url); // all return apiPickupSuccess

  // stateful constants
  const [display, setDisplay] = useState(0);
  const [customCtrls, setCustCtrls] = useState({});
  const [error, setError] = useState(null);

  const modal = {
      title: context.modal.title,
      description: context.modal.description,
      yes: {name: 'Return to Dashboard', call: () => navigate('/')}
    }
  
  const handleSubmit = () => {
    if(!error && !sendLoading) { // We don't care if we have bad sent data. We want to be able to try again if we have cleared the error by changing our number. was !sendData && 
      // setCustCtrls({ ...controls, // Set the controls to show saving instead of submit and disable the button. Let other values remain as they were.
      //   next: {
      //     ...controls.next,
      //     name: 'Saving',
      //     enable: false
      //   }
      // });
      send(context.payload);
    }
  };

  // process the data when it returns from Omnis
  useEffect (() => {
    if (sendData && sendData.success === true) {
      const stayOn = navState.stepCurrent
      const newMenu =  navState.stepArray.map(item => ({ ...item, click: false }));
      changeState({ ...navState, stepCurrent: stayOn, stepArray: newMenu });
      
      switch (context.mode) { // We can use a switch if we need to dispatch unique values for pickups and conversions, otherwise the above ternary is sufficient.
        case 'DF': // dropfreight
          dispatcher({page: 'Consignment', name: 'conNumber', value: sendData.conNumber});
          break;
        case 'QC':
          dispatcher({page: 'Instructions', name: 'pickupNumber', value: sendData.pickupNumber});
          // fallthrough
        default: // pickups and convert quote, can add cases if needed later.
          dispatcher({page: 'Consignment', name: 'conNumber', value: sendData.conNumber})
          dispatcher({page: 'Instructions', name: 'status', value: 1}) // when saved set the status so it is 'locked'
      }
    } else if (sendData && sendData.success === false) {
      setError(sendData.message);
    }
  }, [sendData]) // eslint-disable-line

  useEffect (() => {
    if (!sendError) {
      switch (store.conType.value) {
        case 'manual':
          setCustCtrls({ ...customCtrls,
            next: {
              ...customCtrls.next,
              call: handleSubmit,
              name: sendLoading ? 'Saving' : 'Submit',
              enable: ( store.conNumber.value === '' | sendLoading) ? false : true,
              hidden:(store.conType.value==='manual' ? false : true)
            }
          });
          if(sendData && sendData.success) {
            authModal(modal);

          }
          setDisplay(1);
          break;

        case 'electronic':
          setCustCtrls({});
          setDisplay(2);
          if(!sendData && !sendLoading) {
            dispatcher({page: 'Consignment', name: 'conNumber', value: ''})
            handleSubmit();
          }
          break;

        default:
          setCustCtrls({ ...controls,
            next: {
              ...controls.next,
              call: handleSubmit,
              name: sendLoading ? 'Saving' : 'Submit',
              enable: (error | store.conNumber.value === '' | sendLoading) ? false : true,
              hidden:(store.conType.value==='manual' ? false : true)
            }
          });
          setDisplay(0);
      }
    }
  }, [store, sendLoading]) // eslint-disable-line

  useEffect (() => {
    if (store.conType.value === 'manual') setError(null); //When we change the Consignment number and are manual, clear any errors.
  },[store.conNumber.value]); // eslint-disable-line

  return (
    <CardForm title="Create Consignment Note" className='workflow'
      controls={customCtrls} error={error ? error : sendError}
    >
      {display < 2 && <>
      <div className='stack'>
        <p>Thank you for using the Bishops Transport Customer Portal.</p>
        <div className='cluster' >
          <div className='cluster cluster-outer error-msg'>
            <FontAwesomeIcon icon={faTriangleExclamation} size='2x' />
            <p>{context.notice}</p>
          </div>
        </div>
      </div>
      <div className='cluster cluster-outer' style={{justifyContent: 'center', alignItems: 'stretch'}}>
        <CardButton
          name='conType'
          title='I have a manual connote'
          id={true}
          icon={<FontAwesomeIcon icon={faClipboardListCheck} size='3x'/>}
          onChange={(e) => dispatcher({page: 'Consignment', name: e.target.name, value: 'manual'})}
          checked={store ? store.conType.value === 'manual' : false}
          className='stack'
        />
        <CardButton
          name='conType'
          title='I want an electronic connote'
          id={false}
          icon={<FontAwesomeIcon icon={faDesktopArrowDown} size='3x'/>}
          onChange={(e) => dispatcher({page: 'Consignment', name: e.target.name, value: 'electronic'})}
          checked={store ? store.conType.value === 'electronic': false}
          className='stack'
        />
      </div></>}
      {display === 1 && <ManualConsignment store={store} dispatcher={dispatcher} />}
      {display === 2 && <ElectronicConsignment store={store} setError={setError} modal={modal} navigate={navigate}/>}
    </CardForm>
  )
}


function ManualConsignment({store, dispatcher}) {
  const handleChange = e => {
    dispatcher({page: 'Consignment', name: e.target.name, value: e.target.value})
  }
  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Manual Consignment</p>
        <p>Please enter your consignment number:</p>
        <input name='conNumber' type='text' onChange={handleChange} onInput={handleChange} value={store.conNumber.value} required/>
      </div>
    </section>
  )
}

export default PageConsignment;
export { init, reducer };