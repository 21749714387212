/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageConsignmentLabel.js
Author:    Jordan R. Bock
Version:   0.03
Description:
The Consignment download page for consignment labels. Used by all workflows at summary screen after a consignment is generated.

History:
0.03  16-10-24 JRB   Replaced print in handlePDF with doPrint to determine if we need to generate a PDF or use an existing one. This is only done to maintain original functionality.
0.02  15-10-24 JRB   Moved from DirectDropFreight folder to common. Set print to false. We don't want to generate and file a new label, we should fetch the existing one.
0.01	17-07-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom'
import CardForm, { CardButton } from './CardForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import useRest from '../Session/useRest';
import JsFileDownloader from 'js-file-downloader';
import print from 'print-js';
import { BeatLoader } from 'react-spinners';
import useAuthenticate from '../Session/useAuthenticate';

class init {

}

function reducer(consignNumber, action) {
  let error = null;
  let newValue = action.value;

  switch(action.name) {
     case 'loadStore':
      return {
      };

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }
    
  // return { [action.name]: { value: newValue, error: error } };
}

function PageConsignmentLabel() {
  const location = useLocation();
  let consignNumber = '';
  if (location.state && location.state.hasOwnProperty('conNumber')) {
    consignNumber = location.state.conNumber;
  } else {
    console.log('Error getting consignment number')
  }
  const navigate = useRouterNavigate();

  const modal = {
      title: 'Print Consignment Label',
      description: [
        `Please print this label and attach it to your freight.`
      ],
      yes: {name: 'Return to Dashboard', call: () => navigate('/')}
    }

  return (
    <CardForm title="Print Label" className='workflow'
      controls={{}} 
    >
       <PrintLabel consignNumber={consignNumber} navigate={navigate} modal={modal}/>
    </CardForm>
  )
}

function PrintLabel({consignNumber, modal}) {
  const { authModal } = useAuthenticate();
  const [ retrieve, url ] = useRest('PDF', 'documents/pdf'); // no error handling, as useREST only returns the URL for the PDF.
  const [ generate, status, , docLoading ] = useRest('GET', 'documents/generate');

  const [ saveChecked, setSaveChecked ] = useState(false);
  const [ printChecked, setPrintChecked ] = useState(false);

  const [ disableSavePDF, setDisableSavePDF ] = useState(false);
  const [ disablePrintPDF, setDisablePrintPDF ] = useState(false);
  const [ error, setError ] = useState();
  const [ doPrint, setPrint ] = useState(false); // Assume by default the label must already exist.

  const handlePDF = useCallback((conNum) => {
    // console.log(conNum, status, docError, docLoading);
    
    if (conNum && !docLoading) { // always need a conNum, and don't send a request if one already in progress
      if (status && status.success) {
        if (status.message === conNum)
          retrieve({docRN: conNum, docType: 'LBL', print: doPrint}); // get the PDF.
        else 
          generate({docRN: conNum, docType: 'LBL', print: doPrint});
      }
      else if (status && !status.success) {
        if (status.message !== `${conNum} not found`)
          generate({docRN: conNum, docType: 'LBL', print: doPrint});
        else {
          setPrint(true);
          generate({docRN: conNum, docType: 'LBL', print: doPrint}); // If there is no label. Make one this allows manual consignments to print labels.
        //   setGenError(`${conNum} not found`);
        }
      }
      else if (!status)
        generate({docRN: conNum, docType: 'LBL', print: doPrint});
    }
  }, [status, docLoading, retrieve, generate])

  useEffect(() => {
    consignNumber && handlePDF(consignNumber);
  }, [consignNumber, status, handlePDF]);

  const handlePrint = (enabled) => {
    if (enabled) {
      setPrintChecked(true);
      console.log(url);
      // setDisablePrintPDF(true); // Only allow one click for now
      print({ // onLoadingStart, onLoadingEnd
        printable: url,
        onError: () => {
          setError('Failed to download the PDF');
          // setDisablePrintPDF(false);
          setPrintChecked(false);
        },
        onLoadingEnd: () => { setPrintChecked(false); setError(null); } //authModal(modal);
      });
      // TODO: indicate if there are any errors.
    }
  }
  
  const handleSave = (enabled) => {
    if (enabled) {
      setSaveChecked(true);
      setDisableSavePDF(true); // Only allow one click for now
      const download = new JsFileDownloader({
        url: url,
        autoStart: false,
        forceDesktopMode: true,
        filename: `Consignment ${consignNumber} Label.pdf`
      });
      download.start()
        .then(() => { setError(null) })
        .catch(() => {
          setError('Failed to download the PDF')
          setDisableSavePDF(false) // re-enable on error.
        })
        .finally(() => { setSaveChecked(false); authModal(modal);})
    }
  }

  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Quick Label</p>
        {consignNumber
          ? <>
              <p>A label has been fetched for consignment {consignNumber}.</p>
              {url
                ? <div className='cluster cluster-outer' style={{justifyContent: 'center', alignItems: 'stretch'}}>
                    <CardButton
                      name='eConsignment'
                      title={saveChecked ? 'Downloading PDF' : 'Save as PDF'}
                      id={true}
                      icon={<FontAwesomeIcon icon={faFloppyDisk} size='4x'/>}
                      checked={saveChecked}
                      onChange={handleSave}
                      className='stack'
                      disabled={disableSavePDF}
                    />
                    <CardButton
                      name='eConnote'
                      title={printChecked ? 'Printing PDF' : 'Print label' }
                      id={false}
                      icon={<FontAwesomeIcon icon={faPrint} size='4x'/>}
                      checked={printChecked}
                      onChange={handlePrint}
                      className='stack'
                      disabled={disablePrintPDF}
                    />
                  </div>
                : <div className='cluster cluster-outer' style={{justifyContent: 'center'}}>
                    <p>Loading document</p>
                    <BeatLoader className='react-spinner' loading={true} aria-label="Loading Document" data-testid="loader" />
                  </div>
              }
            </>
          : <>
              <p>Generating label</p>
              <BeatLoader className='react-spinner' loading={true} aria-label="Loading Consignment" data-testid="loader" />
            </>
        }
        {/* {error && <p className="error-msg">{error}</p>} */}
      </div>
    </section>
  )
}


export default PageConsignmentLabel;
export { init, reducer };