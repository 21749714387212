/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  ChangePassword.js
Author:    Paul W Mulroney
Version:   0.01
Description:
The user's settings page.

History:
0.03 20-09-24 JRB   Added check for password being too long. We only allow up to 100 characters
0.02 16-06-22 DBL   removed unused variable and made inputs required.
0.01 03-05-22 PWM   (ld0011330) Fixed test for new password does not match.
0.00 29-04-22 PWM   (ld0011330) Created.
----------------------------------- */

import React, { useEffect, useReducer, useState } from 'react';
import CardForm from '../Common/CardForm';
import useRest from '../Session/useRest';
import './Settings.css'
import { compactStore } from '../Common/utils';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

// Setup state information
class init {
  constructor () {
    // The fields on this form
    this.currentPassword = {value: '', error: null};
    this.newPassword = {value: '', error: null};
    this.confirmPassword = {value: '', error: null};
  }
}

function reducer(store,action) {
    // Reducer does two things: allows us to validate fields, and saves the entry fields into the store.
    let error = null;
    let matchingError = null;
    let value = action.value;

    // Here are our regular expressions that we'll use to validate our password
    const regexNumber = new RegExp('[0-9]');  // Contains a digit
    const regexUpper = new RegExp('[A-Z]+');  // Contains an uppercase char
    const regexLower = new RegExp('[a-z]+');  // Contains a lowercase char

    // validation
    switch (action.name) {
      case 'currentPassword':
        if (value === '')
          error = 'Current Password cannot be blank.';
        // Test is at the server end eg existing password is incorrect.
        return {...store, 'currentPassword': {value: value, error: error}};

      case 'newPassword':
        // Validations for NewPassword:
        if (value === '')
          error = 'New Password cannot be blank.';
        else if (value === store.currentPassword.value)
          error = 'New Password cannot be the same as the Current Password.';
        else if (value.length < 8)
          error = 'New Password must be at least 8 characters.';
        else if (!regexNumber.test(value))
          error = 'New Password must contain a digit.';
        else if (!regexUpper.test(value))
          error = 'New Password must contain an uppercase character';
        else if (!regexLower.test(value))
          error = 'New Password must contain a lowercase character';
        else if (value.length > 100)
          error = 'New Password is too long.'; // Don't tell the user how long is too long. We don't want them intentionally making 100 character passwords.
        if (value !== store.confirmPassword.value)
          matchingError = 'New Password does not match';
        return {...store, 'newPassword': {value: value, error: error}, 'confirmPassword': {value: store.confirmPassword.value, error: matchingError}}
      
      case 'confirmPassword':
        if (value !== store.newPassword.value)
          matchingError = 'New Password does not match';
        return {...store, 'confirmPassword': {value: value, error: matchingError}}
      
      case 'loadStore':
        // Shortcut to save everything - call ourselves to save ourselves
        return {
          ...reducer({}, {name:'currentPassword', value:value.currentPassword}),
          ...reducer({}, {name:'newPassword', value:value.newPassword}),
          ...reducer({}, {name:'confirmPassword', value:value.confirmPassword})
        }
      
      default:
          throw new Error(`Change Password Page (${action.name}) is not supported`);
    }
}

export function ChangePassword() {
  // Note: we don't load the password here, only ever save to the server.
  const [ savePassword, savedOK, saveError, saving ] = useRest('POST', 'changepassword');   // Saving the settings
  const [store, dispatcher ] = useReducer(reducer, new init());
  const [ error, setError ] = useState(null);                                             // Display error messages
  const navigate = useRouterNavigate();                                                   // When we're done, we use this to go back to the dashboard.

  useEffect(() => {
    // display error messages found during validation
    let error = []
    // Any validations found per field in the store
    Object.keys(store).forEach(key => {
      if (store[key].error !== null) {
        error.push(store[key].error)
        error.push(<br />)
      }
    })
    // If saving caused an error, display that.
    if (saveError) error.push(saveError);

    // If any validation failed, display that.  If there are no errors, set it to null so we don't render it.
    if (error.length === 0) error = null;
    setError(error);
  }, [store, saveError]);

  useEffect(() => {
    // once successfully updated on the server, go back to the dashboard.
    if (savedOK) {    
      if (savedOK.success) navigate('/')
      else setError(savedOK.message);     // Didn't save, something went wrong at the server, report that!
    }  
  }, [savedOK, navigate]);

  const handleSave = (enabled) => {
    // User clicked Save
    if (enabled) {
      savePassword(compactStore(store));
    }
  }

  let controls = {
    // Saving var allows us to make the save button say "saving" while it is being saved on the server. Disable button while saving.
     next: { call: handleSave, name: saving ? 'Saving' : 'Save', enable: (saving||error) ? false : true },
     // Cancel takes us back to the dashboard.
     previous: { call: () => navigate('/'), name: 'Cancel', enable: true }
  };

  // Shortcut to define everything we need for each entry field.
  const handleInput = (inputName, classes) => {
    return ({
      id: inputName,
      name: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'ChangePassword', name: e.target.name, value: e.target.value})
    });
  };

  return (
    <CardForm title='New Password' controls={controls} className="margin-block-start user-form set-width" error={error}>
      <div className='stack'>
        <div className='cluster cluster-outer'>
          <label htmlFor='currentPassword'>Current Password: </label>
          <input type="password" required autoComplete="current-password" {...handleInput('currentPassword')}/>
        </div>
        <div className='cluster cluster-outer'>
          <label htmlFor='newPassword'>New Password: </label>
          <input type="password" required autoComplete="new-password" {...handleInput('newPassword')}/>
        </div>
        <div className='cluster cluster-outer'>
          <label htmlFor='confirmPassword'>Confirm Password: </label>
          <input type="password" required autoComplete="new-password" {...handleInput('confirmPassword')}/>
        </div>
      </div>
    </CardForm>
  );
}

export default ChangePassword;