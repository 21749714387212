/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageDetails.js
Author:    John D. Kohl
Version:   0.22
Description:
At this point, the portal only accepts pickups from paying accounts.
Also used in ConvertQuote/ConvertQuote.js

History:
0.22  27-09-24 JRB   (ld0012960) Do not override the set city state entry if we haven't loaded the options yet. If options aren't loaded then city state will be a string. So we check for string type.
0.21  04-12-23 JRB   (ld0012334) Load emailAddress from customer specific default.
0.20  17-11-23 JRB   (ld0012334) Added emailAddress load from droplist selection.
0.19  28-08-23 JDK   (ld0012210) Initialise new pickups with customer specific defaults.
0.18  19-05-23 JRB   (ld0012100) Replaced use of altText with HeaderText. Added HeaderText to business name
0.17  15-05-23 JRB   (ld0012035) Added loadAddresses to support multiple destinations. Commented out excessive console logs.
0.16  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.15  12-04-23 DBL   (ld0012033) When the From cityState changes it clears the To cityState
0.14  29-03-23 DBL   (ld0012009) Allow the two suburb lists be selected by depotFrom and depotTo
0.13  21-03-23 DBL   (ld0011930) load the quote customerRef into pickupRef
0.12  09-03-23 JRB   (ld0011487) Add warning when we select 2 destinations that don't have a rate.
0.11  17-01-23 JDK   Changed Select component to WindowedSelect. This provides a responsive experience, only rendering 20 options at a time.
0.10  10-01-23 JDK   (ld0011869) Added requirePO variable to options; this determines whether a purchase order number is required and sets the field title and properties accordingly.
0.09  21-11-22 JRB   (ld0011770) fixed load quote so that it will not insert incorrect data into the suburb.
0.08  17-11-22 JRB   Change use of list to displaySelections so we can handle quote conversions and prevent changing a set suburb.
0.07  15-11-22 JRB   set depot from depot in value
0.06  11-11-22 DBL   Add max Lengths to fields to match the api
0.05  11-11-22 DBL   (ld0011734) Fixed the cityState dropdowon not loading from an existing record
0.04  22-10-22 JDK   (ld0011678) Change display name of address droplist options, change option "New Address" to option "Manual Address"
0.03  20-10-22 DBL   (ld0011670) Allow intra freight 
0.02  18-10-22 DBL   Added method in the reducer to load a pickup from a quote
0.01  24-08-22 DBL   Added htmlFor, made all IDs unique, made _cityState required via nextCall check
0.00	22-03-22 JDK   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import WindowedSelect from 'react-windowed-select';
import CardForm from '../Common/CardForm';
import { callError } from '../Configuration/Config';
import { customSelect, depotExists, getRates, matchDestinations, titleCase } from '../Common/utils';

class init {
  constructor (s) {
    if (s) this.sender = { // We have a customer specific default to load.
      key: { value: s.id, error: null },
      fullName: { value: `${s.fName} ${s.sName}`.trim(), error: null },
      phoneNum: { value: s.phone, error: null },
      emailAddress: { value: s.emailAddress, error: null },
      streetAddress: { value: `${s.addr1} ${s.addr2} ${s.addr3}`.trim(), error: null },
      _cityState: { value: s.cityState, error: null }, // (suburb object) controls the selection of the suburb, state & postCode
      suburb: { value: s.suburb, error: null },
      state: { value: s.state, error: null },
      postCode: { value: s.postCode, error: null },
      company: { value: s.name, error: null },
      companyABN: { value: '', error: null }, // unique to sender
      error: null
    };
    else this.sender = {
      key: { value: '', error: null },
      fullName: { value: '', error: null },
      phoneNum: { value: '', error: null },
      emailAddress: { value: '', error: null },
      streetAddress: { value:'', error: null },
      _cityState: { value: '', error: null }, // (suburb object) controls the selection of the suburb, state & postCode
      suburb: { value: '', error: null },
      state: { value: '', error: null },
      postCode: { value: '', error: null },
      company: { value: '', error: null },
      companyABN: { value: '', error: null }, // unique to sender
      error: null
    };
    this.receiver = {
      key: { value: '', error: null },
      fullName: { value: '', error: null },
      phoneNum: { value: '', error: null },
      emailAddress: { value: '', error: null },
      streetAddress: { value: '', error: null },
      _cityState: { value: '', error: null }, // (suburb object)controls the selection of the suburb, state & postCode
      _rates: { value: '', error: null }, // array of rates the sender/receiver pair can do be
      suburb: { value: '', error: null },
      state: { value: '', error: null },
      postCode: { value: '', error: null },
      company: { value: '', error: null },
      error: null
    };
    this.poNumber = {value: '', error: null};
    this.pickupRef = {value: '', error: null};
  }
}

/*  action should be the parent object of all address elements,
    store should be the parent object containing the "opposing" address
    (if action is from the sender, store should be the receiver)
*/
function preventSameAddress(action, store) {
  // (store.key.value === action.id) && console.log('I should throw an error');
  return (store.key.value === action.id
    // store.postCode.value === action.postCode || (ld0011670) Allow intra freight 
    // store._cityState.value.includes(action.suburb)
    ? `Sender and receiver destinations cannot match.`
    : null)
}


function detailsReducer(fullStore, action) {
  // console.log('Details details Reducer: store, action', fullStore, action);
  let error = null;
  let detail = action.detail
  let value = action.value
  let store = fullStore[action.name] ? fullStore[action.name] : {} // only get the sender or receiver store

  switch (detail) {
    case 'key':
      //  console.log('key', store, action);
      if (value === 'new' | value === '') {
        // we want to clear all fields
        let blankStore = (new init())[action.name]; // sender or receiver
        blankStore.key.value = 'new';
        
        if (fullStore.quoteNumber.value !== '') { // If we have a quote then city, suburb, postcode, state and depot is locked in so refill them.
          //blankStore.depot.value = store.depot.value;
          blankStore.postCode.value = store.postCode.value;
          blankStore.state.value = store.state.value;
          blankStore.suburb.value = store.suburb.value;
          blankStore._cityState.value = store._cityState.value;
        }

        return blankStore;
      }
      else if (value.id === null) {
        value = '';
        error = 'Option does not exist.';
      }
      else if (value.id !== '') {
        // console.log(value)
        let newStore = {
          ...store,
          ...detailsReducer({}, {detail: 'fullName', value: [value.fName, value.sName].join(' ').trim() }),
          ...detailsReducer({}, {detail: 'phoneNum', value: value.phone }),
          ...detailsReducer({}, {detail: 'emailAddress', value: value.emailAddress }),
          ...detailsReducer({}, {detail: 'streetAddress', value: [value.addr1, value.addr2, value.addr3].join(' ').trim() }),
          ...detailsReducer({}, {detail: 'suburb', value: value.suburb}),
          ...detailsReducer({}, {detail: 'state', value: value.state}),
          ...detailsReducer({}, {detail: 'postCode', value: value.postCode}),
          ...detailsReducer({}, {detail: 'company', value: value.name }),
          ...(action.name === 'sender'
            ? detailsReducer({}, {detail: 'companyABN', value: '' })
            : {_rates: {value: getRates(value._senderCityState.value, value.suburbObj, value.depots), error: null}}),
          _cityState: {value: value.hasOwnProperty('suburbObj') ? value.suburbObj : '', error: null}, // only set if we have a matching suburb object
          key: { value: value.id, error: value.id === null ? true : null }  // keep the key
        };

        if (fullStore && fullStore.quoteNumber.value !== '') { // If we have a quote then city, suburb, postcode, state and depot is locked in so refill them.
          newStore.postCode.value = store.postCode.value;
          newStore.state.value = store.state.value;
          newStore.suburb.value = store.suburb.value;
          newStore._cityState.value = store._cityState.value;
        }
        return newStore;
      }
      // else value.id === ''
      break;

    case 'phoneNum':
      break;

    case 'fullName':
    case 'emailAddress':
    case 'streetAddress':
    case 'suburb':
    case 'state':
    case 'postCode':
    case 'company':
    case 'companyABN':
      // set the key to new because editing any details will make a 'new' contact
      if (store.hasOwnProperty('key')) store.key = {value: 'new', error: ''}
      break;

    case '_cityState':
      const depots = value.depots // extract the depots from the action

      if (store.hasOwnProperty('_rates')) { // receiver _cityState
        const senderSuburb = value._senderCityState.value // extract the sender suburb from the action

        if (value.value) {
          const rate = getRates(senderSuburb, value, depots)
          if (rate !== null)
            return {
              ...store,
              suburb: { value: value.suburb, error: null },
              state: { value: value.state, error: null },
              postCode: { value: value.postCode, error: null },
              key: { value: 'new', error: null }, // edited the contact
              _cityState: { value: value, error: null },
              _rates: { value: rate, error: null }
            }
          else // rates not found
            return {
              ...store,
              suburb: { value: '', error: null },
              state: { value: '', error: null },
              postCode: { value: '', error: null },
              key: { value: 'new', error: null }, // edited the contact
              _cityState: { value: value, error: `${titleCase(value.label)} is not available!` },
              _rates: { value: '', error: null }
            }
        } else // the receiver field was cleared
          return {
            ...store,
            suburb: { value: '', error: null },
            state: { value: '', error: null },
            postCode: { value: '', error: null },
            key: { value: 'new', error: null }, // edited the contact
            _cityState: { value: '', error: `${titleCase(action.name)} suburb is required!` },
            _rates: { value: '', error: null }
          }

      } else { // sender _cityState
        if (value.value) {
          if (value.depot.length > 0 && depotExists(value, depots, 'depotFrom'))// check the selected depot matches a from depot
            return {
              ...store,
              suburb: { value: value.suburb, error: null },
              state: { value: value.state, error: null },
              postCode: { value: value.postCode, error: null },
              key: { value: 'new', error: null }, // edited the contact
              _cityState: { value: value, error: null }
              // have to clear the receiver
            }
          else // From depot not found
            return {
              ...store,
              suburb: { value: '', error: null },
              state: { value: '', error: null },
              postCode: { value: '', error: null },
              key: { value: 'new', error: null }, // edited the contact
              _cityState: { value: value, error: `${titleCase(value.label)} is not available!` }
              // have to clear the receiver
            }
        } else // the sender field was cleared
          return {
            ...store,
            suburb: { value: '', error: null },
            state: { value: '', error: null },
            postCode: { value: '', error: null },
            key: { value: 'new', error: null }, // edited the contact
            _cityState: { value: '', error: `${titleCase(action.name)} suburb is required!` }
            // have to clear the receiver
          }
      } // end of _cityState
    
    case 'loadStore':
      return {
        key: { value: 'new', error: null },                         // always set to new?
        fullName: { value: value.fullName, error: null },
        phoneNum: { value: value.phoneNum, error: null },
        emailAddress: { value: value.emailAddress, error: null },
        streetAddress: { value: value.streetAddress, error: null },
        _cityState: { value: 'loading', error: null },              // DetailsList will populate
        suburb: { value: value.suburb, error: null },
        state: { value: value.state, error: null },
        postCode: { value: value.postCode, error: null },
        company: { value: value.company, error: null },
        ...(action.name === 'sender'
          ? {companyABN: {value: value.companyABN, error: null}}
          : {_rates: {value: '', error: null}}
        )
      }

    default:
      throw new Error(`Details Reducer (${detail}) is not supported`);
  }

  // all the errors get bubbled up in the reducer
  return { ...store, [detail]: { value: value, error: error } }
}


// Receives the store property from the NewPickup store and the action
function reducer(store, action) {
  // console.log('Page Details Reducer: store, action', store, action);
  let error = null;
  let newValue = action.value;

  switch (action.name) {
    case 'poNumber':
      if (action.requirePO && !action.value) error = 'Purchase Order # is required.'; // LD0011869
      break;
    case 'pickupRef':
      break; // these values are not required, so no validation here.

    case 'sender':
      if (action.detail === 'key' && action.value && action.value.key !== 'new') error = preventSameAddress(action.value, store.receiver);
      newValue = detailsReducer(store, action);

      if (action.detail === '_cityState') // the sender address has changed clear the receiver address
        store.receiver = {
          ...store.receiver,
          suburb: { value: '', error: null },
          state: { value: '', error: null },
          postCode: { value: '', error: null },
          key: { value: 'new', error: null },
          _cityState: { value: '', error: null },
          _rates: { value: '', error: null }
        }
      break;
      
    case 'receiver':
      if (action.detail === 'key' && action.value && action.value.key !== 'new') error = preventSameAddress(action.value, store.sender);
      newValue = detailsReducer(store, action);
      break;

    case 'loadStore':
      return {
        sender: detailsReducer(store, {name: 'sender', detail: 'loadStore', value: newValue.sender}),
        receiver: detailsReducer(store, {name: 'receiver', detail: 'loadStore', value: newValue.receiver}),
        ...reducer(store, {name: 'poNumber', value: newValue.poNumber}),
        ...reducer(store, {name: 'pickupRef', value: newValue.pickupRef})
      };

    case 'loadAddresses':
      const senderStore = detailsReducer(store, {name: 'sender', detail: '_cityState', value: {...newValue.sender, depots: newValue.depots}})
      const receiverStore = detailsReducer(store, {name: 'receiver', detail: '_cityState', value: {...newValue.receiver, depots: newValue.depots, _senderCityState: senderStore._cityState }})
      //console.log('loading', senderStore, receiverStore);
      return {
        ...store,
        sender: senderStore,
        receiver: receiverStore,
      }
    
    case 'loadQuote':
      const options = action.options
      let newStore = store

      const s_suburb = options.suburbs.find(item => item.value === newValue.sender)
      const r_suburb = options.suburbs.find(item => item.value === newValue.receiver)

      //  we need .sender and .receiver as the details reducer only returns the sender or receiver part of the store
      newStore.sender = detailsReducer(newStore, {
        name: 'sender',
        detail: '_cityState',
        value: {...s_suburb, depots: options.depots}
      })

      newStore.receiver = detailsReducer(newStore, {
        name: 'receiver',
        detail: '_cityState',
        value: {...r_suburb, depots: options.depots, _senderCityState: {value: s_suburb, error: null} }
      })
      
      // look for an address in the customer's address list, and return the id if it matches the one entered in NewQuote.
      const s_address = options.addresses.find(item => `${item.suburb}, ${item.state} ${item.postCode}`.toUpperCase() === options.sender)
      const r_address = options.addresses.find(item => `${item.suburb}, ${item.state} ${item.postCode}`.toUpperCase() === options.receiver)

      // if we found a matching id, send that to the Details page, so we can pre-select that option.
      // _cityState sets key to new so do this last.
      if (s_address) newStore.sender = detailsReducer(newStore, {name: 'sender', detail: 'key', value: { ...s_address, suburbObj: s_suburb }})
      if (r_address) newStore = reducer(newStore, {name: 'receiver', detail: 'key', value: { ...r_address, suburbObj: r_suburb, _senderCityState: newStore.sender._cityState }}) // use regular reducer so we can return in format {sender:, receiver:}

      newStore.pickupRef = reducer(newStore, {name: 'pickupRef', value: newValue.customerRef}).pickupRef // ld0011930

      return newStore
      
    default:
      throw new Error(`Details Reducer (${action.name}) is not supported`);
  }

  // Bubble up all nested items' errors into error.
  if (action.name === 'sender' || action.name === 'receiver') {
    let items = Object.values(newValue).filter(item => item !== null); // don't try to iterate over a null item.
    let tempError = items.flatMap(item => item.error ? [ item.error, <br />] : []);
    error = error ? error + tempError : tempError;
    if (error.length === 0) error = null;
  }

  // We may have updated the other sender/receiver, return both
  switch(action.name) {
    case 'sender':
      let receiver = store.receiver;
      return { sender: {...newValue, error: error}, receiver: { ...receiver, error: null } };
    case 'receiver':
      let sender = store.sender;
      return { receiver: {...newValue, error: error}, sender: { ...sender, error: null } };
    default:
      return { [action.name]: { value: newValue, error: error } };
  }
}


function PageDetails({controls, options, store, dispatcher}) {
  const [ error, setError ] = useState(null);
  const [ toSuburbs, setToSuburbs ] = useState(null);
  const purchaseOrderFieldTitle = 'Purchase Order' + (options.requirePO ? '' : ' (Optional)'); // LD0011869 - change the text to include '(Optional)' only if purchase orders are not required. 


  // display any error messages
  useEffect(() => {
    let error = [];
    ['poNumber', 'pickupRef', 'sender', 'receiver'].forEach(name => {
      if (store[name].error) {
        error.push(store[name].error);
        error.push(<br />);
      }
    });
    if (error.length === 0) error = null;
    else error.push(`Fix the issue, or ${callError}`);
    setError(error);
  }, [store]);


  // When a sender _cityState is selected populate the receiver suburbs, see also DetailsList()'s useEffect(). These two are co-dependent.
  useEffect(() => {
    if (options && typeof store.sender._cityState.value !== 'string') // if the options object is loaded, and if the user has provided a suburb/postcode combination (either manually or via the pre-defined list). Note this is only a string when it is unset.
      setToSuburbs(matchDestinations(store.sender._cityState.value, options.suburbs, options.depots)) // enable the destinations which correspond to rates for the given pricelist.
    else if (typeof store.sender._cityState.value !== 'string') // options may not be loaded yet and/or no selection has been made for the sender, or (more critically) the sender selection has been reset. If we are waiting for the options to load. Don't run this.
      setToSuburbs(null) // so clear the receiver suburbs/drop list
  }, [options, store.sender._cityState.value])

  const handleInput = (inputName, classes) => {
    return ({
      id: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Details', name: e.target.id, value: e.target.value, requirePO: options.requirePO}), // the reducer does not know about options. Therefore to validate we pass in the option as a key-value pair which the reducer can check on.
      onBlur: (e) => dispatcher({page: 'Details', name: e.target.id, value: store[inputName].value.trim(), requirePO: options.requirePO}), // as above.
    });
  };


  // Add extra validation to the next button so React-Select is required
  const nextCall = (enabled) => {
    const senderValue = store.sender._cityState.value
    const receiverValue = store.receiver._cityState.value

    if (!senderValue || senderValue.length === '' || typeof senderValue === 'string') //|| !options.suburbs.find(s => s.value === senderValue))
      dispatcher({page: 'Details',
        name: 'sender',
        detail: '_cityState',
        value: {depots: options.depots} })
    else if (!receiverValue || receiverValue.length === '' || typeof receiverValue === 'string') //|| !options.suburbs.find(s => s.value === receiverValue))
      dispatcher({page: 'Details',
        name: 'receiver',
        detail: '_cityState',
        value: {depots: options.depots, _senderCityState: store.sender._cityState} })
    else
      controls.next.call(enabled)
  }


  return (
    <CardForm title='Details'
      controls={{ ...controls, next: {...controls.next, call: nextCall, enable: !error} }}
      error={error}
      className="workflow"
    >
      <section className='stack'>
        <div className='cluster cluster-outer force2column' style={{alignItems:'flex-start'}}>
          <DetailsList sender={true}
            list={options.addresses}
            suburbs={options.suburbs}
            depots={options.depots}
            details={store.sender}
            dispatcher={dispatcher}
            quoteNumber={store.quoteNumber.value}
          />
          <DetailsList sender={false}
            list={options.addresses}
            suburbs={toSuburbs}
            depots={options.depots}
            details={{...store.receiver, _senderCityState: store.sender._cityState}}
            dispatcher={dispatcher}
            quoteNumber={store.quoteNumber.value}
          />
        </div>
      </section>
      <section className='accent'>
      <div className='stack'>
        <p className = 'h3'>{purchaseOrderFieldTitle}</p>
        <p>If you have a purchase order number, enter it below.</p>
        <label htmlFor='poNumber'>Purchase Order Number</label>
        <input type='text' {...handleInput('poNumber', 'width-50')} maxLength='100' required={options.requirePO ? true : undefined}/>
      </div>
      </section>
      <section className='accent'>
      <div className='stack'>
        <p className = 'h3'>Customer Reference (Optional)</p>
        <p>If you have a Customer Reference Number, enter it below.</p>
        <label htmlFor='pickupRef'>Customer Reference Number</label>
        <input type='text' {...handleInput('pickupRef', 'width-50')} maxLength='100' />
      </div>
      </section>
    </CardForm>
  );
}


function DetailsList({details, dispatcher, sender, list, suburbs, depots, quoteNumber}) {
  const [pageName] = useState(sender ? 'sender' : 'receiver');
  const [headerText] = useState(sender ? "Sender's" : "Receiver's");
  // const [altText] = useState(sender ? 'Your ' : ''); // This was originally applied to Full Name, Phone Number, Email Address and Address
  const [displaySelections, setSelections] = useState(null) // the predefined address droplist

  const handleInput = (inputName, classes) => { // generic input handling, for most fields in this component.
    if (details) return ({
      id: `${pageName}-${inputName}`, // ids need to be unique
      name: inputName,
      value: details[inputName].value,
      className: details[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Details', name: pageName, detail: e.target.name, value: e.target.value}),
      onBlur: (e) => dispatcher({page: 'Details', name: pageName, detail: e.target.name, value: details[inputName].value.trim()}),
      disabled: (details.key.value === 'new') ? undefined : false
    });
    else return null;
  };

  useEffect(() => {
    if (list && suburbs) // We do not initially have the list so only process when we do
      setSelections(list
        .map(item => { // if we have a suburb object add it so we set _cityState
          const suburbObj = suburbs.find(s => // find the corresponding suburb object
            s.suburb===item.suburb && s.postCode===item.postCode && s.state===item.state && s.depot.includes(item.depot))
          return suburbObj
            ? {...item, suburbObj: {
              ...suburbObj,
              ...(pageName === 'receiver' ? {_senderCityState: details._senderCityState} : {}),
              depots
            }}
            : item
        })
        .map(item => {
          return {...item, disable: !(item.hasOwnProperty('suburbObj') && (quoteNumber === '' || (quoteNumber !== '' && [details.suburb.value, details.state.value, details.postCode.value].every(value => item.suburbObj.value.includes(value)))))}
        }) // LD0012108 - changed to enable predefined addresses in convert quote, where those addresses match the quoted suburb
      )
    else // If we don't have a list, this may be because the sender "_cityState" object has been unset - deliberately.
      setSelections(null)
  }, [list, details, suburbs, quoteNumber])

  // useEffect(()=>console.log('the display selections',displaySelections),[displaySelections])

  const handleDetailsChange = (e) => { // handle the address droplist changes.
    if (e.target.value === 'new')
      dispatcher({ page: 'Details', name: pageName, detail: e.target.name, value: 'new' })
    else
      dispatcher({ page: 'Details', name: pageName, detail: e.target.name, value: {
        ...displaySelections.find(item => item.id === e.target.value),
        ...(pageName === 'receiver' ? {_senderCityState: details._senderCityState } : {}),
        depots: depots
      }
      });
  }

  const handleSelect = (inputName) => ({ // handle the city/suburb droplist changes.
    // the option contains label, available, item{suburb, state, postCode}
    ...customSelect,
    inputId: `${pageName}-${inputName}`,
    isLoading: depots === null,
    value: suburbs && suburbs.find(s => s.label === details[inputName].value.label)
      ? suburbs.find(s => s.label === details[inputName].value.label)
      : '',
    options: suburbs ? suburbs : undefined,
    className: `${customSelect.classNamePrefix}-outline measure${details[inputName].error ? ' error-outline' : ''}`,
    isDisabled: quoteNumber !== '',
    windowThreshold: 20,
    onChange: item => dispatcher({page: 'Details', name: pageName, detail: inputName, value: {...item, depots,
      ...(pageName === 'receiver' ? {_senderCityState: details._senderCityState } : {})} }),
  })

  // JSX - details = the store object child associated with this instance of the details (store.sender or store.receiver)
  if (details) { return (
    <div className='stack flex-grow'>
      {/* The droplist for pre-defined addresses */}
      <label htmlFor={`${pageName}-key`} className='h3'>{headerText} Details</label>
      <select id={`${pageName}-key`} name='key'
        onChange={handleDetailsChange}
        value={details.key.value} 
        className={details.error ? ' error-outline measure' : 'measure'}
        required
      >
        <option value='' disabled hidden>Please make a selection</option>
        {/* list is defined from the schema apiAddress in Omnis rtPortal */}
        {displaySelections && displaySelections.map((item, index) =>
          <option key={index} value={item.id} disabled={item.disable}>
            {`${item.name}: ${titleCase(item.addr1)}${item.addr1 && ', '}${titleCase(item.suburb)}`.trim()}
          </option>
        )}
        <option value='new' > {/*selected new address? clear the display*/}
          Manual Address
        </option>
      </select>

      <label htmlFor={`${pageName}-fullName`}>{headerText} Full Name</label>
      <input type='text' {...handleInput('fullName')} maxLength='50' required />

      <label htmlFor={`${pageName}-phoneNum`}>{headerText} Phone Number</label>
      <PhoneInput
        id={`${pageName}-phoneNum`}
        defaultCountry='AU'
        country='AU'
        placeholder='e.g. 411 888 999'
        value={details.phoneNum.value}
        onChange={number => number !== undefined && dispatcher({page: 'Details', name: pageName, detail: 'phoneNum', value: number})}
        className={'measure'}
        required
      />

      <label htmlFor={`${pageName}-emailAddress`}>{headerText} Email Address</label>
      <input type='text' {...handleInput('emailAddress')} maxLength='1000' />

      <label htmlFor={`${pageName}-streetAddress`}>{`${headerText} Address`}</label>
      <input type='text' {...handleInput('streetAddress')} maxLength='100' required />
      
      <label htmlFor={`${pageName}-_cityState`}>Suburb/City</label>
      <WindowedSelect {...handleSelect('_cityState')} required />

      <label htmlFor={`${pageName}-postCode`}>Postcode</label>
      <input type='text' {...handleInput('postCode')} disabled />

      <label htmlFor={`${pageName}-company`}>{headerText} Business Name</label>
      <input type='text' {...handleInput('company')} maxLength='50' required/>

      {sender &&
        <div className="stack" >
          <label htmlFor={`${pageName}-companyABN`} className='stack'>ABN (Optional)</label>
          <input type='text' {...handleInput('companyABN')} maxLength='100' />
        </div>
      }
    </div>
  )} else {
    return <></>
  }
}

export default PageDetails
export { init, reducer }