/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  ElectronicConsignment.js
Author:    Jordan R. Bock
Version:   0.01
Description:
The electronic consignment portion of PageConsignment from both NewPickup and DirectDropFreight.

History:
0.01	11-10-24 JRB   Extracted from NewPickup/PageConsignment.js 
----------------------------------- */

import React, { useEffect, useState, useCallback } from 'react';
import { CardButton } from './CardForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import useRest from '../Session/useRest';
import JsFileDownloader from 'js-file-downloader';
import print from 'print-js';
import { BeatLoader } from 'react-spinners';
import useAuthenticate from '../Session/useAuthenticate';

function ElectronicConsignment({store, setError, modal}) {
  const { authModal } = useAuthenticate();
  const [ retrieveCon, conUrl ] = useRest('PDF', 'documents/pdf'); // no error handling, as useREST only returns the URL for the PDF.
  const [ retrieveLBL, lblUrl ] = useRest('PDF', 'documents/pdf'); // no error handling, as useREST only returns the URL for the PDF.
  const [ generate, status, , docLoading ] = useRest('GET', 'documents/generate');
  const [ generateLbl, Lblstatus, , lblLoading ] = useRest('GET', 'documents/generate');

  const [ saveChecked, setSaveChecked ] = useState(false);
  const [ printChecked, setPrintChecked ] = useState(false);
  const [ labelChecked, setLabelChecked ] = useState(false);

  const [ disableSavePDF, setDisableSavePDF ] = useState(false);
  const [ disablePrintPDF, setDisablePrintPDF ] = useState(false);

  const handlePDF = useCallback((conNum) => {
    // console.log('Handle PDF');
    // console.log(conNum, status, docLoading);
    // console.log(conUrl);
    let print = true;
    if (conNum && !docLoading) { // always need a conNum, and don't send a request if one already in progress
        if (status && status.success) {
          if (status.message === conNum) {
            if (conUrl === null)
              retrieveCon({docRN: conNum, docType: 'CON', print: print}); // get the PDF.
            else
              handleLabelPDF(conNum);
          }
          else 
            generate({docRN: conNum, docType: 'CON', print: print});
        }
        else if (status && !status.success) {
          // if (status.message !== `${conNum} not found`)
            generate({docRN: conNum, docType: 'CON', print: print});
          // else
          //   setGenError(`${conNum} not found`);
        }
        else if (!status)
          generate({docRN: conNum, docType: 'CON', print: print});
      }
  }, [status, docLoading, retrieveCon, generate, conUrl])

  const handleLabelPDF = useCallback((conNum) => {
    // console.log('Handle Label');
    // console.log(conNum, Lblstatus, lblLoading);
    // console.log(lblUrl);
    let print = true;
    if (conNum && !lblLoading) { // always need a conNum, and don't send a request if one already in progress
        if (Lblstatus && Lblstatus.success) {
          if (Lblstatus.message === conNum) {
            retrieveLBL({docRN: conNum, docType: 'LBL', print: print}); // get the PDF.           
          }
          else 
          generateLbl({docRN: conNum, docType: 'LBL', print: print});
        }
        else if (Lblstatus && !Lblstatus.success) {
          // if (status.message !== `${conNum} not found`)
          generateLbl({docRN: conNum, docType: 'LBL', print: print});
          // else
          //   setGenError(`${conNum} not found`);
        }
        else if (!Lblstatus)
          generateLbl({docRN: conNum, docType: 'LBL', print: print});
      }
  }, [Lblstatus, lblLoading, retrieveLBL, generateLbl])

  useEffect(() => {
    store.conNumber.value && handlePDF(store.conNumber.value);
  }, [store, status, handlePDF, Lblstatus, handleLabelPDF, conUrl]);

  const handlePrint = (enabled) => {
    if (enabled) {
      setPrintChecked(true);
      // setDisablePrintPDF(true); // Only allow one click for now
      print({ // onLoadingStart, onLoadingEnd
        printable: conUrl,
        onError: () => {
          setError('Failed to download the PDF');
          // setDisablePrintPDF(false);
          setPrintChecked(false);
        },
        onLoadingEnd: () => { setPrintChecked(false); setError(null);  } //authModal(modal);
      });
      // TODO: indicate it is loading and if there are any errors.
    }
  }

  const handlePrintlabel = (enabled) => {
    if (enabled) {
      setLabelChecked(true);
      // setDisablePrintPDF(true); // Only allow one click for now
      print({ // onLoadingStart, onLoadingEnd
        printable: lblUrl,
        onError: () => {
          console.log('downloadError')
          setError('Failed to download the PDF');
          // setDisablePrintPDF(false);
          setLabelChecked(false);
        },
        onLoadingEnd: () => { setLabelChecked(false); setError(null);  } //authModal(modal);
      });
      // TODO: indicate it is loading and if there are any errors.
    }
  }
  
  const handleSave = (enabled) => {
    if (enabled) {
      setSaveChecked(true);
      setDisableSavePDF(true); // Only allow one click for now
      const downloadCon = new JsFileDownloader({
        url: conUrl,
        autoStart: false,
        forceDesktopMode: true,
        filename: `Consignment ${store.conNumber.value}.pdf`
      });
      downloadCon.start()
        .then(() => { setError(null) })
        .catch(() => {
          setError('Failed to download the PDF')
          setDisableSavePDF(false) // re-enable on error.
        })
        .finally(() => { setSaveChecked(false); authModal(modal) })

      const downloadLbl = new JsFileDownloader({
        url: lblUrl,
        autoStart: false,
        forceDesktopMode: true,
        filename: `Consignment ${store.conNumber.value} Label.pdf`
      });
      downloadLbl.start()
        .then(() => { setError(null) })
        .catch(() => {
          setError('Failed to download the PDF')
          setDisableSavePDF(false) // re-enable on error.
        })
        .finally(() => { setSaveChecked(false); authModal(modal) })
    }
  }

  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Electronic Consignment Note</p>
        {store.conNumber.value
          ? <>
              <p>An electronic Consignment Number {store.conNumber.value} has been generated for this freight.</p>
              {lblUrl
                ? <div className='cluster cluster-outer' style={{justifyContent: 'center', alignItems: 'stretch'}}>
                    <CardButton
                      name='eConsignment'
                      title={saveChecked ? 'Downloading PDF' : 'Save as PDF'}
                      id={true}
                      icon={<FontAwesomeIcon icon={faFloppyDisk} size='4x'/>}
                      checked={saveChecked}
                      onChange={handleSave}
                      className='stack'
                      disabled={disableSavePDF}
                    />
                    <CardButton
                      name='eConnote'
                      title={printChecked ? 'Printing PDF' : 'Print connote' }
                      id={false}
                      icon={<FontAwesomeIcon icon={faPrint} size='4x'/>}
                      checked={printChecked}
                      onChange={handlePrint}
                      className='stack'
                      disabled={disablePrintPDF}
                    />
                    <CardButton
                      name='eLabel'
                      title={labelChecked ? 'Printing PDF' : 'Print label' }
                      id={false}
                      icon={<FontAwesomeIcon icon={faPrint} size='4x'/>}
                      checked={labelChecked}
                      onChange={handlePrintlabel}
                      className='stack'
                      disabled={disablePrintPDF}
                    />
                  </div>
                : conUrl ? 
                  <div className='cluster cluster-outer' style={{justifyContent: 'center'}}>
                    <p>Loading label</p>
                    <BeatLoader className='react-spinner' loading={true} aria-label="Loading label" data-testid="loader" />
                  </div>
                  :
                  <div className='cluster cluster-outer' style={{justifyContent: 'center'}}>
                    <p>Loading document</p>
                    <BeatLoader className='react-spinner' loading={true} aria-label="Loading Document" data-testid="loader" />
                  </div>
              }
            </>
          : <>
              <p>Generating consignment</p>
              <BeatLoader className='react-spinner' loading={true} aria-label="Loading Consignment" data-testid="loader" />
            </>
        }
        {/* {error && <p className="error-msg">{error}</p>} */}
      </div>
    </section>
  )
}

export default ElectronicConsignment;