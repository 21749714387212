/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  PageConsignment.js
Author:    Jordan R. Bock
Version:   0.04
Description:
The Consignment download page for the Drop Freight workflow.

History:
0.04  11-10-124 JRB LD0013078 Replaced ElectronicConsignment function with ElectronicConsignment.js 
0.03  19-09-24 JRB  LD0013019 Defined setError to prevent typescript errors. 
0.02  11-03-24 JRB  LD0012666 Inform user to print and bring along connote when a eConNote has been booked.
0.01	05-02-24 JRB   Created.
----------------------------------- */

import React, {  useState } from 'react';
import {  useNavigate as useRouterNavigate } from 'react-router-dom'
import CardForm from '../Common/CardForm';
import ElectronicConsignment from '../Common/ElectronicConsignment';

class init {

}

function reducer(store, action) {
  let error = null;
  let newValue = action.value;

  switch(action.name) {
     case 'loadStore':
      return {
      };

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }
    
  // return { [action.name]: { value: newValue, error: error } };
}

function PageConsignmentDownload({store, dispatcher, controls}) {
  const consignNumber = (store.conNumber && store.conNumber.value) ? store.conNumber.value : null;
  const readyDate = (store._readyAtDate && store._readyAtDate.value) ? store._readyAtDate.value : null;
  // Hooks, custom or otherwise.
  // const { authModal } = useAuthenticate()
  // const { navState, changeState } = useNavigate()
  const navigate = useRouterNavigate();
  const display = (store.conType.value === 'electronic') ? 2 : 1;
  // stateful constants
  // const [customCtrls, setCustCtrls] = useState({});
  const [error, setError] = useState(null);

  const modal = {
      title: 'Drop Freight In booked',
      description: [
        `Your consignment number is ${consignNumber}`,
        <br />,
        `Your drop freight has been booked for ${readyDate}`,
        <br />,
        `Please print this con note and bring in with your freight.`
      ],
      yes: {name: 'Return to Dashboard', call: () => navigate('/')}
    }

  return (
    <CardForm title="Booked Dropfreight" className='workflow'
      controls={{}} error={error ? error : ''}
    >
      {display === 1 && <ManualConsignment store={store}  />}
      {display === 2 && <ElectronicConsignment store={store} setError={setError} modal={modal} navigate={navigate}/>}
    </CardForm>
  )
}

function ManualConsignment({store}) {
  return (
    <section className='accent'>
      <div className='stack'>
        {/* <p className='h3'>Manual Consignment</p> */}
        <p>Thank you for using the Bishops Transport Customer Portal.</p>
        <p>Your drop in has been booked for {store._readyAtDate.value}.</p>
      </div>
    </section>
  )
}

export default PageConsignmentDownload;
export { init, reducer };